// useStudentMessages.js
import { ref } from 'vue';
import axios from 'axios';
import {useMessageStore} from "@/stores/messageStore.js";

export function useStudentMessages() {
    const studentMessages = ref(null);
    const studentMessage = ref(null);
    const newMessage = ref(0);
    const messageStore = useMessageStore();


    const markMessageRead = async (message_id) => {
        try {
            const response = await axios.patch(`/api/messages/${message_id}/mark-as-read`);
            await messageStore.fetchNewMessageFlag();
        } catch (err) {
            console.error('Error marking message read:', err);
        }
    };
    const fetchStudentMessages = async () => {
        try {
            const response = await axios.get(`/api/messages`);
            studentMessages.value = response.data;
        } catch (err) {
            console.error('Error fetching student info:', err);
        }
    };

    const fetchStudentMessage = async (message_id) => {
        try {
            const response = await axios.get(`/api/messages/${message_id}`);
            studentMessage.value = response.data;
        } catch (err) {
            console.error('Error fetching student info:', err);
        }
    };
    const fetchNewMessageFlag = async () => {
        try {
            const response = await axios.get(`/api/messages/new-message`);
            newMessage.value = response.data;
        } catch (err) {
            console.error('Error fetching student info:', err);
        }
    }
    return {markMessageRead, newMessage, fetchNewMessageFlag, studentMessage, fetchStudentMessage, studentMessages, fetchStudentMessages };
}
