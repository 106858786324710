<template>
    <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="64" height="48" :fill="iconColor"/>
        <path d="M21.1295 42.144C18.8615 42.144 17.0495 40.464 17.0495 38.004C17.0495 35.544 18.8615 33.864 21.1295 33.864C22.5815 33.864 23.5655 34.548 24.2015 35.448L23.3615 35.916C22.9055 35.244 22.0775 34.752 21.1295 34.752C19.4135 34.752 18.0815 36.096 18.0815 38.004C18.0815 39.9 19.4135 41.256 21.1295 41.256C22.0775 41.256 22.9055 40.776 23.3615 40.092L24.2135 40.56C23.5415 41.472 22.5815 42.144 21.1295 42.144ZM27.9789 42.144C26.2149 42.144 25.1109 40.776 25.1109 39.096C25.1109 37.416 26.2149 36.06 27.9789 36.06C29.7429 36.06 30.8469 37.416 30.8469 39.096C30.8469 40.776 29.7429 42.144 27.9789 42.144ZM27.9789 41.34C29.2149 41.34 29.8989 40.284 29.8989 39.096C29.8989 37.92 29.2149 36.864 27.9789 36.864C26.7429 36.864 26.0469 37.92 26.0469 39.096C26.0469 40.284 26.7429 41.34 27.9789 41.34ZM36.2261 42V41.184C35.7821 41.688 35.0261 42.144 34.1621 42.144C32.9501 42.144 32.3141 41.556 32.3141 40.296V36.204H33.2141V40.02C33.2141 41.04 33.7301 41.34 34.5101 41.34C35.2181 41.34 35.8901 40.932 36.2261 40.464V36.204H37.1261V42H36.2261ZM38.9234 42V36.204H39.8234V37.14C40.2914 36.528 40.9634 36.084 41.7554 36.084V37.008C41.6474 36.984 41.5394 36.972 41.3954 36.972C40.8434 36.972 40.0874 37.428 39.8234 37.896V42H38.9234ZM42.3804 41.232L42.8484 40.584C43.2324 41.028 43.9644 41.412 44.7564 41.412C45.6444 41.412 46.1244 41.016 46.1244 40.452C46.1244 39.816 45.4164 39.612 44.6364 39.42C43.6524 39.192 42.5364 38.928 42.5364 37.728C42.5364 36.828 43.3164 36.06 44.6964 36.06C45.6804 36.06 46.3764 36.432 46.8444 36.9L46.4244 37.524C46.0764 37.104 45.4404 36.792 44.6964 36.792C43.9044 36.792 43.4124 37.152 43.4124 37.668C43.4124 38.232 44.0724 38.412 44.8284 38.592C45.8364 38.82 47.0004 39.108 47.0004 40.392C47.0004 41.364 46.2204 42.144 44.7204 42.144C43.7724 42.144 42.9684 41.856 42.3804 41.232Z" fill="white"/>
        <path d="M24.0485 9.2449C24.0485 16.9597 24.0485 21.2852 24.0485 29H37.8444M24.0485 9.2449C23.8329 9.2449 24.3718 8.68367 24.9646 8.12245M24.0485 9.2449H24.9107M37.8444 29V9.2449M37.8444 29L38.9222 27.8776M37.8444 9.2449L38.9222 8.12245M37.8444 9.2449H24.9107M40 7V26.7551L38.9222 27.8776M40 7L38.9222 8.12245M40 7H27.0663M27.0663 17.7755H34.8265M38.9222 8.12245H24.9646M38.9222 8.12245V27.8776M24.9646 8.12245C25.3132 7.79241 25.6804 7.46238 25.9238 7.24647C26.104 7.08667 26.3367 7 26.5775 7H27.0663M27.0663 7L24.9107 9.2449M26.6352 11.9388V15.5306H35.2577V11.9388H26.6352Z" stroke="white"/>
    </svg>

</template>

<script setup>
import { tailwindColors } from '@/constants/colors';
import {computed} from "vue";

const props = defineProps({
    selected: Boolean
});

const iconColor = computed(() => {
    return props.selected ? tailwindColors['cali-gold'] : 'none';
});
</script>
