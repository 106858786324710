<template>
    <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="64" height="48" :fill="iconColor"/>
        <path d="M19.9125 42L19.2045 40.224H15.1485L14.4405 42H13.3365L16.5525 33.996H17.7885L21.0165 42H19.9125ZM17.1765 34.884L15.4365 39.336H18.9045L17.1765 34.884ZM21.691 39.096C21.691 37.38 22.855 36.06 24.583 36.06C25.639 36.06 26.263 36.492 26.707 37.068L26.107 37.62C25.723 37.092 25.231 36.864 24.631 36.864C23.395 36.864 22.627 37.812 22.627 39.096C22.627 40.38 23.395 41.34 24.631 41.34C25.231 41.34 25.723 41.1 26.107 40.584L26.707 41.136C26.263 41.712 25.639 42.144 24.583 42.144C22.855 42.144 21.691 40.824 21.691 39.096ZM27.6324 39.096C27.6324 37.38 28.7964 36.06 30.5244 36.06C31.5804 36.06 32.2044 36.492 32.6484 37.068L32.0484 37.62C31.6644 37.092 31.1724 36.864 30.5724 36.864C29.3364 36.864 28.5684 37.812 28.5684 39.096C28.5684 40.38 29.3364 41.34 30.5724 41.34C31.1724 41.34 31.6644 41.1 32.0484 40.584L32.6484 41.136C32.2044 41.712 31.5804 42.144 30.5244 42.144C28.7964 42.144 27.6324 40.824 27.6324 39.096ZM37.8218 42V41.184C37.3778 41.688 36.6218 42.144 35.7578 42.144C34.5458 42.144 33.9098 41.556 33.9098 40.296V36.204H34.8098V40.02C34.8098 41.04 35.3258 41.34 36.1058 41.34C36.8138 41.34 37.4858 40.932 37.8218 40.464V36.204H38.7218V42H37.8218ZM40.1831 39.096C40.1831 37.416 41.3831 36.06 43.0391 36.06C44.7911 36.06 45.8231 37.428 45.8231 39.168V39.396H41.1311C41.2031 40.488 41.9711 41.4 43.2191 41.4C43.8791 41.4 44.5511 41.136 45.0071 40.668L45.4391 41.256C44.8631 41.832 44.0831 42.144 43.1351 42.144C41.4191 42.144 40.1831 40.908 40.1831 39.096ZM43.0271 36.804C41.7911 36.804 41.1791 37.848 41.1311 38.736H44.9351C44.9231 37.872 44.3471 36.804 43.0271 36.804ZM47.7249 35.232C47.4009 35.232 47.1249 34.956 47.1249 34.62C47.1249 34.284 47.4009 34.02 47.7249 34.02C48.0609 34.02 48.3369 34.284 48.3369 34.62C48.3369 34.956 48.0609 35.232 47.7249 35.232ZM47.2809 42V36.204H48.1809V42H47.2809ZM49.9762 42V33.996H50.8762V42H49.9762Z" fill="white"/>
        <path d="M29.7778 29H22V22.2308V15.4615L32 7L42 15.4615V29H34.2222M29.7778 29V22.2308C29.7778 20.4256 31.2593 19.9744 32 19.9744C32.7407 19.9744 34.2222 20.4256 34.2222 22.2308V29M29.7778 29H34.2222" stroke="white"/>
    </svg>
</template>

<script setup>
import { tailwindColors } from '@/constants/colors';
import {computed} from "vue";

const props = defineProps({
    selected: Boolean
});

const iconColor = computed(() => {
    return props.selected ? tailwindColors['cali-gold'] : 'none';
});
</script>
