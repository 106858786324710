import { defineStore } from 'pinia';

export const useMessageStore = defineStore('messageStore', {
    state: () => ({
        newMessage: false,
    }),
    actions: {
        async fetchNewMessageFlag() {
            try {
                const response = await axios.get(`/api/messages/new-message`);
                this.newMessage = response.data;
            } catch (err) {
                console.error('Error fetching new message flag:', err);
            }
        },
        // Add other actions as needed
    },
});
