<script setup>
import ArrowBack from "@/Components/icons/ArrowBack.vue";
import {Head, Link} from "@inertiajs/vue3";
import BottomNavigation from "@/Layouts/Navigation/BottomNavigation.vue";
import {computed, getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
import {Inertia} from "@inertiajs/inertia";
import MyCaliLogoLarge from "@/Components/icons/MyCaliLogoLarge.vue";
import Toast from "primevue/toast";
import useBugsnagUser from "@/composables/useBugsnagUser.js";

const props = defineProps({
    headerLink: String,
    headerTitle: String,
    headerColor: {
        type: String,
        default: 'text-green-600'
    },
    showLogo: Boolean,
    logoColor: String,
    backEvent: Boolean,
    showBottomNavigation: Boolean,
    showTopNavigation: {
        type: Boolean,
        default: true
    },
    paddingMain: {
        type: String,
        default: "px-2"
    },
    currentRouteName: String
});
// Assuming useTranslations composable fetches translations and then sets them


const emit = defineEmits(['backEvent']);
const headerContent = computed(() => ({
    showBackButton: props.headerLink || props.backEvent,
    showLogo: !props.headerTitle && !props.headerLink
}));
const emitBackEvent = () => {
    if (props.backEvent) {
        emit('backEvent');
    }
};
const checkSession = async () => {
    console.log("checking...");
    try {
        await axios.get('/api/check-session');
        // Session is active
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Session has expired
            clearInterval(sessionCheckInterval.value);
            Inertia.visit('/login');
        }
    }
};

// Ref to store the interval ID
const sessionCheckInterval = ref(null);

onMounted(() => {
    const instance = getCurrentInstance();
    if (instance && instance.proxy) {
        const currenturl = instance.proxy.$page.url;
        if (!currenturl.includes('/login')) {
            sessionCheckInterval.value = setInterval(checkSession, 121 * 60 * 1000); // Check every 121  minutes WE NEED TO FIX THIS
        }
    }
});

onUnmounted(() => {
    clearInterval(sessionCheckInterval.value);
});

useBugsnagUser();

</script>

<template>
    <Head :title="headerTitle"/>
    <div class="bg-cali-black ">
        <!-- Mobile Header Component -->
        <div class="text-white pt-12" v-if="showTopNavigation">
            <div class="max-w-md mx-auto">
                <div class="flex items-center justify-between">
                    <!-- Left Container (Back Button and Title) -->
                    <div class="flex flex-1 items-center">
                        <template v-if="headerContent.showBackButton">
                            <div class="flex ml-4">
                                <div v-if="headerLink" class="cursor-pointer" @click="emitBackEvent">
                                    <Link :href="headerLink" v-if="headerLink">
                                        <ArrowBack/>
                                    </Link>
                                </div>
                                
                                <div v-if="backEvent" class="cursor-pointer" @click="emitBackEvent">
                                    <ArrowBack/>
                                </div>
                                
                                <span class="ml-2 text-sm" :class="headerColor" v-if="headerTitle">
                                {{ headerTitle }}
                            </span>
                            </div>
                        </template>
                    </div>
                    
                    <!-- Center Container (Logo) -->
                    <div class="flex justify-center items-center">
                        <Link :href="route('home')">
                            <MyCaliLogoLarge v-if="showLogo"/>
                        </Link>
                    </div>
                    
                    <!-- Right Container (Empty but needed for alignment) -->
                    <div class="flex-1"></div>
                </div>
            </div>
        </div>
        
        
        <div class="min-h-screen flex flex-col justify-between max-w-md mx-auto" :class="paddingMain" >
            <Toast />
            <!-- Main Content -->
            <div class="flex-grow">
                <div class="w-full sm:max-w-md overflow-hidden text-white pb-24" :class="paddingMain">
                    <slot/>
                </div>
                <BottomNavigation v-if="showBottomNavigation" :currentRouteName="currentRouteName"/>
                <div v-if="!showBottomNavigation"
                     class="flex flex-col items-stretch px-3.5 my-6 w-full fixed inset-x-0 bottom-0 max-w-md mx-auto pt-6">
                    <div class="flex justify-end mt-2">
                        <img src="/images/cali-flag-logo.png" alt="CALI Logo" class="w-58 h-12p">
                    </div>
                </div>
            </div>
            
            <!-- Footer -->
            <footer class="flex flex-row justify-between items-center w-full sm:max-w-md mt-4 px-6">
                <div>
                    <slot name="footer"/>
                </div>
            </footer>
        </div>
    </div>
</template>

<style>
.p-toast-message-success {
    background-color: #28a745; /* Custom success color */
    color: white;
}
</style>

