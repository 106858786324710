// colors.js

export const tailwindColors = {
    "cali-black": '#000000',
    "cali-gold": '#AF9665',
    "cali-gray": '#838383',
    "cali-blue": '#7394FC',
    "cali-green": '#009933',
    "cali-yellow": '#FFC422',
    'cali-violet': '#EC89F9',
    "cali-orange": '#FF9933',
    "cali-coral": "#FF6666",
    "cali-super-light-gray": "#B6B6B6",
    "cali-super-light-gray-2": "#FCFCFC",
    "cali-gray-dark": '#1A1A1A',
    "cali-gray-dark-2": '#2A2A2A',
    "cali-primary-contrast": '#363636',
    "cali-mint": '#8EFF8C',
};
