<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.191 13H3.80902C3.43733 13 3.19558 12.6088 3.3618 12.2764L7.55279 3.89443C7.73705 3.5259 8.26295 3.5259 8.44721 3.89443L12.6382 12.2764C12.8044 12.6088 12.5627 13 12.191 13Z"
            fill="#FF6666"/>
        <mask id="path-2-inside-1_2289_3756" fill="white">
            <path d="M7.5 10V7C7.5 6.83333 7.6 6.5 8 6.5C8.16667 6.5 8.5 6.6 8.5 7V10C8.5 10.4 8.16667 10.5 8 10.5C7.83333 10.5 7.5 10.4 7.5 10Z"/>
        </mask>
        <path
            d="M6.5 7V10H8.5V7H6.5ZM9.5 10V7H7.5V10H9.5ZM9.5 7C9.5 6.47408 9.25847 6.04886 8.8968 5.78847C8.58613 5.56478 8.24336 5.5 8 5.5V7.5C7.94848 7.5 7.90811 7.49323 7.87372 7.4834C7.83793 7.47317 7.78581 7.45302 7.7282 7.41153C7.66695 7.36744 7.60277 7.30109 7.5566 7.21159C7.51001 7.12128 7.5 7.04276 7.5 7H9.5ZM8 5.5C7.47408 5.5 7.04886 5.74153 6.78847 6.1032C6.56478 6.41387 6.5 6.75664 6.5 7H8.5C8.5 7.05152 8.49323 7.09189 8.4834 7.12628C8.47317 7.16207 8.45302 7.21419 8.41153 7.2718C8.36744 7.33305 8.30109 7.39723 8.21159 7.4434C8.12128 7.48999 8.04276 7.5 8 7.5V5.5ZM6.5 10C6.5 10.5259 6.74153 10.9511 7.1032 11.2115C7.41387 11.4352 7.75664 11.5 8 11.5V9.5C8.05152 9.5 8.09189 9.50677 8.12628 9.5166C8.16207 9.52683 8.21419 9.54698 8.2718 9.58847C8.33305 9.63256 8.39723 9.69891 8.4434 9.78841C8.48999 9.87872 8.5 9.95724 8.5 10H6.5ZM7.5 10C7.5 9.95724 7.51001 9.87872 7.5566 9.78841C7.60277 9.69891 7.66695 9.63256 7.7282 9.58847C7.78581 9.54698 7.83793 9.52683 7.87372 9.5166C7.90811 9.50677 7.94848 9.5 8 9.5V11.5C8.24336 11.5 8.58613 11.4352 8.8968 11.2115C9.25847 10.9511 9.5 10.5259 9.5 10H7.5Z"
            fill="black" mask="url(#path-2-inside-1_2289_3756)"/>
        <path d="M8.5 11.5C8.5 11.7761 8.27614 12 8 12C7.72386 12 7.5 11.7761 7.5 11.5C7.5 11.2239 7.72386 11 8 11C8.27614 11 8.5 11.2239 8.5 11.5Z" fill="black"/>
    </svg>
</template>
