<template>
    <div class="bg-cali-black flex-col items-stretch px-1 py-3 w-full fixed inset-x-0 bottom-0 max-w-md mx-auto pt-3">
        <div class="grid grid-cols-5 gap-1 px-0.5 w-full text-xs">
            <!-- Home Link -->
            <Link :href="route('home')">
                <HomeIcon :selected="currentRouteName === 'home'"/>
            </Link>

            <!-- Registration Link -->
            <Link :href="route('registration')">
               <RegistrationIcon :selected="currentRouteName === 'registration'"/>
            </Link>

            <!-- Courses Link -->
            <Link :href="route('courses')">
                <CourseIcon :selected="currentRouteName === 'courses'"/>
            </Link>

            <!-- Messages Link -->
            <Link :href="route('messages')">
                <div class="relative w-16 h-12"> <!-- Width and height adjusted for MessagesIcon -->
                    <MessagesIcon :selected="currentRouteName === 'messages'" class="w-full h-full"/>
                    <div v-if="messageStore.newMessage" class="absolute top-3 right-4">
                        <MailAlert class="w-4 h-4 transform -translate-y-1/2 translate-x-1/2" />
                    </div>
                </div>
            </Link>
            <!-- Account Link -->
            <Link :href="route('user-profile')">
                <SettingsIcon :selected="currentRouteName === 'settings'"/>
            </Link>
        </div>
        <div class="flex justify-end mt-2">
            <img src="/images/cali-flag-logo.png" alt="myCALI Logo" class="w-58 h-12p">
        </div>
    </div>
</template>


<script setup>
import {Link} from "@inertiajs/vue3";
import MessagesIcon from "@/Components/icons/MessagesIcon.vue";
import HomeIcon from "@/Components/icons/HomeIcon.vue";
import CourseIcon from "@/Components/icons/CourseIcon.vue";
import RegistrationIcon from "@/Components/icons/RegistrationIcon.vue";
import SettingsIcon from "@/Components/icons/SettingsIcon.vue";
import MailAlert from "@/Components/icons/MailAlert.vue";
import {onMounted} from "vue";
import {useStudentMessages} from "@/composables/student/useStudentMessages.js";
import {useMessageStore} from "@/stores/messageStore.js";

const props = defineProps({
    currentRouteName: String
});

const {newMessage, fetchNewMessageFlag} = useStudentMessages();
const messageStore = useMessageStore();

onMounted( async () => {
    await messageStore.fetchNewMessageFlag();
})
</script>
